<template>
  <SlickList
    v-model="data"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <SlickItem
      v-for="(item, index) in data"
      :index="index"
      :key="index"
      :item="item"
    >
      <slot :item="item"></slot>
    </SlickItem>
  </SlickList>
</template>

<script type="text/ecmascript-6">
import { SlickList, SlickItem } from 'vue-slicksort';

export default {
  name: 'drag-list',

  components: {
    SlickList,
    SlickItem,
  },

  props: {
    value: {
      type: Array,
    }
  },

  model: {
    event: 'change',
    props: 'value'
  },

  computed: {
    data: {
      get: function () {
        return this.value
      },
      set: function (newValue) {
        this.$emit('change', newValue)
      }
    }
  },

  data() {
    return {

    }
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
</style>