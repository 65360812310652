var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DragList", {
    class: _vm.prefixClass,
    attrs: { axis: "x", "lock-to-container-edges": true, "press-delay": 0 },
    on: { input: _vm.handleSort },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function (ref) {
          var item = ref.item
          return [
            _c("div", { staticClass: "ark-item-wrap" }, [
              _c("div", { staticClass: "icon-wrap" }, [
                _c("i", { staticClass: "iconfont icon-tuozhuai" }),
              ]),
              _vm._v(" "),
              _c("span", { staticClass: "tag-name" }, [
                _vm._v(_vm._s(item[_vm.labelField])),
              ]),
            ]),
          ]
        },
      },
    ]),
    model: {
      value: _vm.dragList,
      callback: function ($$v) {
        _vm.dragList = $$v
      },
      expression: "dragList",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }