import { render, staticRenderFns } from "./DragList.vue?vue&type=template&id=461b729a&scoped=true&"
import script from "./DragList.vue?vue&type=script&lang=js&"
export * from "./DragList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "461b729a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/chenzihao/code/workspace/arkui-bus/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('461b729a')) {
      api.createRecord('461b729a', component.options)
    } else {
      api.reload('461b729a', component.options)
    }
    module.hot.accept("./DragList.vue?vue&type=template&id=461b729a&scoped=true&", function () {
      api.rerender('461b729a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/drag-list/DragList.vue"
export default component.exports