// 国际化
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import zh from '@syman/burgeon-r3-locale/locale/zh-CN.json'

// const zh = require('./zh').default
// const en = require('./en').default
window.BCL_lang = {
  zh, // 兼容之前的。以后按zh-CN标准
  'zh-CN': zh, // 中文语言包
  // en, // 英文语言包
}

// const lang = localStorage.getItem('ark-ui-bcl-lang') || 'zh-CN'

// 如果外部项目没有挂载i18n则主动挂载下
// 使用方或组件至少use一次。但是不能同时use，会报错
if (!('$i18n' in Vue.prototype)) {
  Vue.use(VueI18n)
}

const i18n = new VueI18n({
  locale: 'zh-CN', // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  messages: BCL_lang,
  silentTranslationWarn: true // 去掉警告
})
// const i18n = new VueI18n()

// 处理大小写。例如zh-cn转zh-CN
function reviseLocale(locale) {
  if(locale.includes('-')) {
    // 如zh-cn的情况
    const localeArr = locale.split('-')
    return localeArr[0].toLowerCase() + '-' + localeArr[1].toUpperCase()
  } else if(locale.includes('_')) {
    const localeArr = locale.split('_')
    return localeArr[0].toLowerCase() + '-' + localeArr[1].toUpperCase()
  } else {
    // 如en的情况
    return locale.toLowerCase()
  }
}

// 挂全局方便测试用
window.BCL_i18n = function(language) {
  const lang = reviseLocale(language)
  i18n.locale = lang
  localStorage.setItem('ark-ui-bcl-lang', language)
}

export const locale = BCL_i18n // 暴露接口给引用方使用

// 设置新语言
export const BCL_setLocaleMessage = function( locale, message) {
  i18n.setLocaleMessage(reviseLocale(locale), message)
}

// 合并语言
export const BCL_mergeLocaleMessage = function( locale, message) {
  i18n.mergeLocaleMessage(reviseLocale(locale), message)
}

export default i18n
