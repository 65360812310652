import Viewer from 'v-viewer'
import VueDND from 'awe-dnd'
 


 
// 版本
import packJson from '../package.json';
// 组件
import arkBclConfig from '../config/nameConfig.js';
import  arkDropDownSelectFilter from './components/drop-down-select-filter/index.js';
import  arkMopMultiSelect from './components/mop-multi-select/index.js';
import  arkDropMultiSelectFilter from './components/drop-multi-select-filter/index.js';
import  arkWangeditor from './components/wangeditor/index.js';
import  arkDocfile from './components/docfile/index.js';
import  arkImageUpload from './components/image-upload/index.js';
import  arkPreviewPicture from './components/preview-picture/index.js';
import  arkEnumerableInput from './components/enumerable-input/index.js';
import  arkExtensionProperty from './components/extension-property/index.js';
import  arkCommonTableByAgGrid from './components/common-table-by-ag-grid/index.js';
import  arkButton from './components/button/index.js';
import  arkDragList from './components/drag-list/index'
import  arkIconfontPicker from './components/iconfontPicker'
import  arkDragTag from './components/drag-tag'
// 多语言
import { locale, BCL_setLocaleMessage, BCL_mergeLocaleMessage } from './utils/i18n/index';



const arkBclCompontent = {
    arkDropDownSelectFilter,
    arkDropMultiSelectFilter,
    arkMopMultiSelect,
    arkWangeditor,
    arkDocfile,
    arkImageUpload,
    arkPreviewPicture,
    arkEnumerableInput,
    arkExtensionProperty,
    arkCommonTableByAgGrid,
    arkButton,
    arkDragList,
    arkIconfontPicker,
    arkDragTag
}
const install = function(Vue, opts = {}) {
    if (install.installed) {
        return;
        }
    Vue.use(Viewer)
    Vue.use(VueDND)
    //opts.defaultZindex
    Object.keys(arkBclCompontent).forEach(key => {
        Vue.component(`${key}`,  arkBclCompontent[key]);
    });
};

// auto install
if (typeof window !== 'undefined' && window.Vue) {
    install(window.Vue);
}

export default {
    name:'ark-bcl',
    version: packJson.version,
    install,
    locale,
    BCL_setLocaleMessage,
    BCL_mergeLocaleMessage,
    ...arkBclCompontent
}
