var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "SlickList",
    _vm._g(
      _vm._b(
        {
          model: {
            value: _vm.data,
            callback: function ($$v) {
              _vm.data = $$v
            },
            expression: "data",
          },
        },
        "SlickList",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.data, function (item, index) {
      return _c(
        "SlickItem",
        { key: index, attrs: { index: index, item: item } },
        [_vm._t("default", null, { item: item })],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }