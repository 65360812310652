<template>
  <DragList
    :class="prefixClass"
    axis="x"
    :lock-to-container-edges="true"
    :press-delay="0"
    v-model="dragList"
    @input="handleSort"
  >
    <template v-slot="{item}">
      <div class="ark-item-wrap">
        <div class="icon-wrap">
          <i class="iconfont icon-tuozhuai"></i>
        </div>
        <span class="tag-name">{{item[labelField]}}</span>
      </div>
    </template>
  </DragList>
</template>

<script type="text/ecmascript-6">
import Config from '../../../config/nameConfig';
import DragList from '../drag-list'
import './DragTag.less'
const prefixName = `${Config.prefixCls}DragTag`;
const prefixClass = `${Config.classFix}drag-tag`;

export default {
  name: prefixName,

  components: {
    DragList
  },

  props: {
    /**
     * 列表值
     */
    value: {
      type: Array
    },
    /**
     * 指定label的取值字段
     */
    labelField: {
      type: String,
      default: 'label'
    },
    /**
     * 指定value的取值字段
     */
    valueField: {
      type: String,
      default: 'value'
    },
    /**
     * 原始列表
     */
    list: {
      type: Array
    }
  },

  model: {
    props: 'value',
    event: 'on-change'
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.dragList = this.list
        if(this.value && this.value.length > 0) {
          this.sort()
        }
      }
    }
  },

  data() {
    return {
      prefixClass,
      dragList: []
    }
  },

  methods: {
    sort() {
      const newList = []
      this.value.forEach(value => {
        const obj = this.list.find(item => item[this.valueField] === value)
        newList.push(obj)
      })
      this.dragList = newList
    },

    handleSort(e) {
      const valueList = []
      e.forEach((item) => {
        valueList.push(item[this.valueField])
      })
      this.$emit('on-change', valueList, e)
    }
  },
}
</script>

<style lang="less" scoped>
</style>