<template>
  <div>
    <ag-grid-table
      ref="agGridTable"
      :options="filteredOption"
      :columns="columns"
      :data="data"
      :renderParams="renderFn"
      :mode="mode"
      :cellStyle="cellStyle"
      :class="[border ? 'ag-border': '']"
      v-on="$listeners"
      v-bind="$attrs"
      @on-selection-change="tableSelectedChange"
      @on-row-dblclick="tableRowDbclick"
      @grid-ready="tableGridReady"
    ></ag-grid-table>
  </div>
</template>

<script type="text/ecmascript-6">
// import AgGridTable from './AgGridTable.vue'
import Vue from 'vue'
import ImageComponent from './renderComponents/ImageComponent.vue'
import FkComponent from './renderComponents/FkComponent.vue'
import TextComponent from './renderComponents/TextComponent.vue'
import MopFkComponent from './renderComponents/MopFkComponent.vue'
import CustomerUrlComponent from './renderComponents/CustomerUrlComponent.vue'
import SequenceComponent from './renderComponents/SequenceComponent.vue'
import AttachmentComponent from './renderComponents/AttachmentComponent.vue'
import FieldMergeComponent from './renderComponents/FieldMergeComponent.vue'
import IconfontComponent from './renderComponents/IconfontComponent.vue'
import i18n from '../../utils/i18n'

const Common_Table_Mode = 'commonTable'
Vue.component('ImageComponent',ImageComponent) // 为了让框架普通表格能用，这里采用全局注册
Vue.component('FkComponent',FkComponent)
Vue.component('TextComponent',TextComponent)
Vue.component('MopFkComponent',MopFkComponent)
Vue.component('CustomerUrlComponent',CustomerUrlComponent)
Vue.component('SequenceComponent',SequenceComponent)
Vue.component('AttachmentComponent',AttachmentComponent)
Vue.component('FieldMergeComponent',FieldMergeComponent)
Vue.component('IconfontComponent',IconfontComponent)

export default {
  name: 'CommonTableByAgGrid',

  inheritAttrs: false,

  components: {
    AgGridTable: () => import(
    /* webpackChunkName: "AgGridTable" */
    `./AgGridTable.vue`),
    // ImageComponent,
    // FkComponent,
    // MopFkComponent,
    // CustomerUrlComponent,
    // SequenceComponent,
    // AttachmentComponent,
    // FieldMergeComponent,
    // IconfontComponent
  },

  props: {
    /**
     * ag配置
     */
    options: {
      type: Object,
      default: () => ({})
    },
    /**
     * 行数据
     */
    data: {
      default: []
    },
    /**
     * 列数据
     */
    columns: {
      default: []
    },
    /**
     * 列自定义组件渲染参数
     */
    renderParams: {
      type: Function
    },
    // 表格展示模式，目前有commonTable(兼容详情普通表格),r3-list(列表)
    mode: {
      type: String,
      default: ''
    },
    /**
     * 是否加边框
     */
    border: {
      type: Boolean
    },
    // r3定制表格列方法
    r3ColumnRenderer: {
      // type: Function
    },
  },

  data() {
    return {
      api: null, // 表的api
      columnApi: null, // 表格列的api
    }
  },

  computed: {
    // 根据表格展示模式，重组表格参数
    filteredOption() {
      const option = {}
      const commonTableOptions = {
        // floatingFilter: false
      }

      switch (this.mode) {
        case Common_Table_Mode:
          return Object.assign(option, commonTableOptions, this.options)
        default:
          return this.options
      }
    },

    // 获取自定义渲染函数
    renderFn() {
      return this.mode === 'r3-list' ? this.listRender : this.renderParams
    }
  },

  /**
   * ag被修改的方法统一用ag开头。避免和$listeners事件冲突
   */
  methods: {
    // 表格选中
    tableSelectedChange(e) {
      /**
       * 表格选中
       */
      this.$emit('ag-selection-change', e)
    },

    // 行双击事件
    tableRowDbclick(e) {
      if (this.mode === Common_Table_Mode) {
      /**
       * 表格行双击事件
       */
        this.$emit('ag-row-dblclick', e.data)
      } else {
        this.$emit('ag-row-dblclick', e)
      }
    },

    // 表格渲染完毕
    tableGridReady(e) {
      this.api = this.$refs.agGridTable.api
      this.columnApi = this.$refs.agGridTable.columnApi
      /**
       * 表格挂载完毕(数据不一定渲染完)
       */
      this.$emit('grid-ready', e)
    },

    // 单元格样式
    cellStyle(params) {
      const { colDef,data } = params;
      // 设置高亮
      // 规则:1.单据标记key_group优先级更高
      // 2.只有列表页生效。表头不管
      // 3.作用域是表单时不生效
      // 4.外键字段不高亮
      // 5.非合计总计字段不高亮
      const highlight = colDef.webconf && colDef.webconf.highlight
      if (highlight && highlight.scope !== 'form' && !colDef.key_group && this.mode !== 'commonTable' && !colDef.fkdisplay) {
        const datas = this.options.datas || {}
        if((datas.isFullRangeSubTotalEnabled || datas.isSubTotalEnabled) && data.__ag_is_statistic_row__ && !colDef.issubtotal) {
          return
        }
        let style = {}
        highlight.rules.forEach(rule => {
          const func = new Function('v',`return ${rule.operator}`)
          let value = data[colDef.colId]
          if(value !==null && typeof value === 'object') { // 取出对象里的val
            value = value.hasOwnProperty('refobjval') ? value.refobjval : value.val // 要考虑字段选项组
          }
          // 去掉合计总计里111,122.00 里的逗号
          if((datas.isFullRangeSubTotalEnabled || datas.isSubTotalEnabled) && data.__ag_is_statistic_row__ && colDef.issubtotal) {
            value = value.toString().replace(/,/g, '')
          }
          // 数字空值也不要高亮
          if(colDef.type === 'NUMBER' && (value === '' || value === null)) {
            return
          }
          if(colDef.type === 'NUMBER'){
            value = Number(value)
          }
          if(func(value)) {
            Object.keys(rule.style).forEach(k => {
              // 排除textDecoration,fontWeight,fontStyle的默认值
              if(rule.style[k] && rule.style[k] !== 'normal' && rule.style[k] !== 'none'){
                style[k] = rule.style[k]
              }
            })
          }
        })
        return style
      }
      return undefined
    },

    emptyAllFilters() {
      this.api && this.api.setFilterModel(null);
      if (this.$refs.agGridTable) {
        this.$refs.agGridTable.$el.querySelectorAll('.ag-floating-filter-input').forEach(e => { e.value = '' })
      }
    },

    fixAgRenderChoke() {
      this.$refs.agGridTable && this.$refs.agGridTable._fixAgRenderChoke()
    },

    // r3列表渲染逻辑
    listRender(cellData) {
      let renderObj = {
        renderContainer: 'CellRender',
        renderComponent: TextComponent
      }
      if (cellData.display === 'doc') {
        renderObj.renderComponent = AttachmentComponent
      }
      if (cellData.colname === 'ID') {
        renderObj.renderComponent = SequenceComponent
      }
      if (cellData.customerurl) {
        // 单元格点击跳转到列表的场景时，外键也按这个组件渲染
        renderObj.renderComponent = CustomerUrlComponent
      }
      
      if (cellData.isfk && !cellData.customerurl) {
        if (cellData.fkdisplay === 'mop') {
          renderObj.renderComponent = MopFkComponent
        } else if (cellData.fkdisplay === 'drp' || cellData.fkdisplay === 'pop') {
          renderObj.renderComponent = FkComponent
        }
      }

      if (cellData.display === 'image') {
        renderObj.renderComponent = ImageComponent
      }

      if (cellData.key_group && cellData.key_group.length > 0) {
        renderObj.renderComponent = FieldMergeComponent
      }
      
      if (cellData.display === 'iconfontpicker') {
        renderObj.renderComponent = IconfontComponent
      }

      // 最初版的定制列
      if (cellData.webconf && cellData.webconf.customerurl && cellData.webconf.customerurl.objdistype === 'defined') {
        const componentName = cellData.webconf.customerurl.cellcomponent
        const renderer = window.ProjectConfig.standardTableCellRenderer && window.ProjectConfig.standardTableCellRenderer[componentName]
        renderObj.renderContainer = 'CellRenderByFunction'; // 表示用render方式渲染
        if (typeof renderer !== 'function') {
          // renderObj.renderComponent = (h) => h('span', {
          //   domProps: {
          //     innerHTML: this.$t('messages.noComponent')
          //   }
          // });
          renderObj.renderContainer = 'CellRender'
          renderObj.renderComponent = componentName
        } else {
          renderObj.renderComponent = (h, params) => h('div', {
            domProps: {
              innerHTML: `${renderer(params)}`
            }
          });
        }
      }

      if (this.r3ColumnRenderer) {
        this.r3ColumnRenderer(cellData, renderObj)
      }

      return renderObj
    }
  },

  beforeCreate() {
    this.$t = i18n.t.bind(i18n)
  },
}
</script>

<style lang="less" scoped>
</style>