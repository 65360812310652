<template>
  <component
    :is="type==='dropdown' ? 'dropdown': 'div'"
    @on-click="handleDropClick"
    class="inline-block"
  >
    <!-- 带提示按钮 -->
    <Poptip
      :trigger="popTrigger"
      transfer
      :wordWrap="popWordWrap"
      :width="popWidth"
      :content="popContent"
      :placement="placement"
      v-if="enablePop"
    >
      <Button
        :type="type ==='dropdown' ? 'fcdefault' : type"
        :class="prefixClass"
        v-on="$listeners"
        v-bind="$attrs"
      >
        <!-- @slot 按钮内容 -->
        <slot name="default"></slot>
        <Icon
          type="ios-arrow-down"
          v-if="type==='dropdown'"
        />
      </Button>
    </Poptip>

    <template v-else>
      <!-- 普通按钮 -->
      <Button
        :type="type ==='dropdown' ? 'fcdefault' : type"
        :class="prefixClass"
        v-on="$listeners"
        v-bind="$attrs"
      >
        <slot name="default"></slot>
        <Icon
          type="ios-arrow-down"
          v-if="type==='dropdown'"
        />
      </Button>
    </template>

    <template :slot="'list'">
      <!-- @slot 子菜单列表。仅按钮为dropdown类型时生效 -->
      <slot
        name="list"
        v-if="$slots.list"
      >
      </slot>

      <DropdownMenu v-else>
        <DropdownItem
          v-for="item in data"
          :key="item"
          :name="item"
        >{{item}}</DropdownItem>
      </DropdownMenu>

    </template>
  </component>
</template>

<script type="text/ecmascript-6">
import Config from '../../../config/nameConfig';
import './button.less'
const prefixName = `${Config.prefixCls}button`;
const prefixClass = `${Config.classFix}button`;

export default {
  name: prefixName,

  props: {
    /**
     * 按钮类型
     */
    type: {
      default: 'posdefault',
      type: String,
      validator: function (value) {
        return ["posdefault", "fcdefault", 'dropdown'].indexOf(value) !== -1;
      },
    },
    /**
     * 气泡宽度
     */
    popWidth: {
      default: '200',
      type: [String, Number]
    },
    /**
     * 气泡触发方式。可选值为hover（悬停）click（点击
     */
    popTrigger: {
      default: 'hover',
      type: String,
      validator: function (value) {
        return ["hover", "click"].indexOf(value) !== -1;
      },
    },
    /**
     * 气泡内容是否自动换行
     */
    popWordWrap: {
      type: Boolean,
      default: true
    },
    /**
     * 气泡内容
     */
    popContent: {
      type: String
    },
    /**
     * 是否开启气泡功能
     */
    enablePop: {
      type: Boolean,
      default: false
    },
    /**
     * 提示框出现的位置
     */
    placement: {
      default: 'top',
      type: String,
      validator: function (value) {
        return ["top", "top-start", 'top-end', 'bottom', 'bottom-start', 'bottom-end', 'left', 'left-start', 'left-end', 'right', 'right-start', 'right-end'].indexOf(value) !== -1;
      },
    },
    /**
     * 下拉列表数据
     */
    data: {
      default: () => ([]),
      type: Array
    }
  },

  data() {
    return {
      prefixClass,
    }
  },

  methods: {
    handleDropClick(e) {
      /**
       * 点击菜单事件
       */
      this.$emit('clickMenu', e)
    }
  },
}
</script>

<style lang="less" scoped>
</style>