var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ag-icon-font" },
    [
      _vm._l(_vm.iconList, function (item, index) {
        return [
          item.text
            ? _c(
                "Tooltip",
                {
                  key: index,
                  attrs: {
                    trigger: "hover",
                    transfer: "",
                    placement: "top",
                    "max-width": "200",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "ag-icon-font-pop",
                      attrs: { slot: "content" },
                      slot: "content",
                    },
                    [_vm._v(_vm._s(item.text))]
                  ),
                  _vm._v(" "),
                  _c("i", {
                    class: "iconfont " + item.icon,
                    style: { color: item.color, cursor: "pointer" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !item.text
            ? _c("i", {
                key: index,
                class: "iconfont " + item.icon,
                style: { color: item.color },
              })
            : _vm._e(),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }